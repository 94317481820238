import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typo from '@dolstaff/shared/es/Typo';
import { Section, Container } from '../../Blocks';
import Image from '../../Image';
import ZoomOnScrolling from '../../ZoomOnScrolling';

import { ourStoryDefault } from '../consts';

const Main = styled.div`
  margin-bottom: 4em;

  @media screen and (min-width: 1024px) {
    margin-bottom: 12em;
  }
`;

const Heading = styled.h2`
  font-size: 24px;
  color: #242c34;
  max-width: 150px;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 1.5em;

  @media screen and (min-width: 768px) {
    font-size: 40px;
    max-width: 280px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 48px;
    max-width: 320px;
  }
`;

const StoryList = styled.div``;
const StoryItem = styled.div`
  position: relative;
  margin-bottom: 3em;

  .storyHeader {
    display: flex;
    align-items: center;
  }
  .bullet {
    font-size: 20px;
    flex: 40px 0 0;
  }
  .title {
    font-size: 20px;
    line-height: 1.4;
    margin: 0;
  }
  .content {
    font-size: 16px;
    line-height: 1.6;
  }

  @media screen and (min-width: 768px) {
    .content {
      font-size: 20px;
    }
    .title {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 4em;

    .storyHeader {
      flex: 40%;
    }
    .content {
      flex: 60%;
      margin-left: 4em;
      max-width: 680px;
    }
    .title {
      max-width: 540px;
    }
  }
`;

const FancyBox = styled.div`
  position: absolute;
  top: -2em;
  right: 1em;
  z-index: 1;
  width: 120px;
  height: 120px;

  .emHeading {
    width: 120px;
    position: relative;

    &:after {
      content: '';
      width: 200px;
      height: 2px;
      background-color: black;
      transform: rotate(-240deg);
      display: block;
      position: absolute;
      right: -6em;
      display: none;
    }
  }

  .subtitle {
    max-width: 120px;
    position: absolute;
    bottom: 0;
    right: 0;
    text-transform: uppercase;
  }

  @media screen and (min-width: 1024px) {
    width: 220px;
    height: 180px;
    top: -2.5em;
    right: 2em;

    .emHeading {
      width: 220px;
    }
  }
`;

const Banner = styled.div`
  margin-bottom: 2em;
  margin-right: 24px;

  .bannerWrp {
    position: relative;
  }
  .backgroundImg {
    overflow: hidden;
    max-height: 500px;
  }

  @media screen and (min-width: 1024px) {
    margin-right: 8em;
    margin-bottom: 8em;
  }
`;

const emHeading = {
  handle: 'YIwtnHiPSHaJQhI1oCnQ',
  width: 1124,
  height: 457
};

class DolStory extends React.PureComponent {
  render() {
    const ourStory = {
      ...ourStoryDefault,
      ...this.props.ourStory
    };

    return (
      <Section>
        <Container>
          <Heading>{ourStory.title}</Heading>
        </Container>
        <Banner>
          <div className="bannerWrp">
            <FancyBox>
              <div className="emHeading">
                <Image image={emHeading} maxWidth={350} />
              </div>
            </FancyBox>
            <div className="backgroundImg">
              <ZoomOnScrolling>
                <Image
                  image={ourStory.historyImage}
                  maxWidth={1440}
                  backgroundColor="white"
                />
              </ZoomOnScrolling>
            </div>
          </div>
        </Banner>

        <Container>
          <Main>
            <StoryList>
              <StoryItem>
                <div className="storyHeader">
                  <div className="bullet">01</div>
                  <h4 className="title">{ourStory.historyTitle1}</h4>
                </div>
                <Typo
                  className="content"
                  htmlContent={ourStory.historyContent1.html}
                />
              </StoryItem>
              <StoryItem>
                <div className="storyHeader">
                  <div className="bullet">02</div>
                  <h4 className="title">{ourStory.historyTitle2}</h4>
                </div>
                <Typo
                  className="content"
                  htmlContent={ourStory.historyContent2.html}
                />
              </StoryItem>
              <StoryItem>
                <div className="storyHeader">
                  <div className="bullet">03</div>
                  <h4 className="title">{ourStory.historyTitle3}</h4>
                </div>
                <Typo
                  className="content"
                  htmlContent={ourStory.historyContent3.html}
                />
              </StoryItem>
              <StoryItem>
                <div className="storyHeader">
                  <div className="bullet">04</div>
                  <h4 className="title">{ourStory.historyTitle4}</h4>
                </div>
                <Typo
                  className="content"
                  htmlContent={ourStory.historyContent4.html}
                />
              </StoryItem>
              <StoryItem>
                <div className="storyHeader">
                  <div className="bullet">05</div>
                  <h4 className="title">{ourStory.historyTitle5}</h4>
                </div>
                <Typo
                  className="content"
                  htmlContent={ourStory.historyContent5.html}
                />
              </StoryItem>
              {ourStory.historyTitle6 && (
                <StoryItem>
                  <div className="storyHeader">
                    <div className="bullet">06</div>
                    <h4 className="title">{ourStory.historyTitle6}</h4>
                  </div>
                  <Typo
                    className="content"
                    htmlContent={ourStory.historyContent6.html}
                  />
                </StoryItem>
              )}
            </StoryList>
          </Main>
        </Container>
      </Section>
    );
  }
}

DolStory.propTypes = {
  ourStory: PropTypes.shape({})
};

export default DolStory;
