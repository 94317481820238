import styled from 'styled-components';

export const Main = styled.div`
  margin-bottom: 3.5em;

  .body {
    font-size: 16px;
    max-width: 820px;

    .title {
      font-size: 24px;
      margin: 0.5em 0;
    }
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 5em;

    .body {
      font-size: 20px;
      max-width: 820px;

      .title {
        font-size: 32px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 7.5em;
    .body {
      font-size: 24px;
      max-width: 820px;

      .title {
        font-size: 32px;
      }
    }
  }
`;

export const Heading = styled.h2`
  font-size: 24px;
  margin-bottom: 1.4em;

  @media screen and (min-width: 768px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 48px;
  }
`;
