import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import MySEO from '../../shared/MySEO';
import MainLayout from '../../shared/MainLayout';
import DolStory from '../../shared/OurStory/DolStory';
import OurMission from '../../shared/OurStory/OurMission';
import OurVision from '../../shared/OurStory/OurVision';
import CoreValues from '../../shared/OurStory/CoreValues';
import DolTeam from '../../shared/OurStory/DolTeam';
import DolCulture from '../../shared/OurStory/DolCulture';
import JobList from '../../shared/OurStory/JobList';
import Footer from '../../shared/OurStory/Footer';

class OurStory extends React.PureComponent {
  render() {
    const { jobList, ourStoryList } = this.props.data.gcms;
    const ourStory = ourStoryList[0] || {};

    return (
      <MainLayout hideSideCTA noMenu noFooter>
        <MySEO
          title="Mục Tiêu và Sứ Mệnh của DOL IELTS Đình Lực"
          description="Xem câu chuyện của DOL English để hiểu thêm về mục tiêu, sứ mệnh của DOL trong hành trình đơn giản hóa việc giảng dạy tiếng Anh nói riêng & giáo dục nói chung."
        />
        <DolStory ourStory={ourStory} />
        <OurMission ourStory={ourStory} />
        <OurVision ourStory={ourStory} />
        <CoreValues ourStory={ourStory} />
        <DolTeam ourStory={ourStory} />
        <DolCulture ourStory={ourStory} />
        <JobList jobList={jobList} />
        <Footer />
      </MainLayout>
    );
  }
}

OurStory.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.shape({
      jobList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      ourStoryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    })
  })
};

export default OurStory;

export const pageQuery = graphql`
  query {
    gcms {
      ourStoryList: atwPageOurStories(stage: PUBLISHED, first: 1) {
        stage
        updatedAt
        createdAt
        id
        title
        historyTitle1
        historyTitle2
        historyTitle3
        historyTitle4
        historyTitle5
        historyTitle6
        historyContent1 {
          html
        }
        historyContent2 {
          html
        }
        historyContent3 {
          html
        }
        historyContent4 {
          html
        }
        historyContent5 {
          html
        }
        historyContent6 {
          html
        }
        misstionTitle
        missionContent {
          html
        }
        missionImage {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        historyImage {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        visionTitle
        visionImage {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        visionContent {
          html
        }
        coreValueTitle
        coreValueImage {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        coreValueContent {
          html
        }
        teamTitle
        teamImage {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        team1Title
        team1Content {
          html
        }
        team2Title
        team2Content {
          html
        }
        team3Title
        team3Content {
          html
        }
        team4Title
        team4Content {
          html
        }
        team5Title
        team5Content {
          html
        }
        cultureTitle
        cultureImage {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        cultureContent {
          html
        }
      }

      jobList: atwJobs(
        stage: PUBLISHED
        where: { domainType: DolEnglish, type_not: Teacher }
      ) {
        stage
        updatedAt
        createdAt
        id
        team
        title
        address
        salaryDisplay
        reasonToJoin1
        reasonToJoin2
        reasonToJoin3
        jobDescription {
          html
        }
        requirement {
          html
        }
        benefit {
          html
        }
        aboutCompany
        banner {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        summary
        slug
        type
      }
    }
  }
`;
