import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cl from 'classnames';

import { InView } from 'react-intersection-observer';

const Main = styled.div`
  overflow: hidden;

  .zoomOnScrollingBody {
    transition: all 1.5s;
    animation-delay: 1.5s;

    &.active {
      transform: scale(1.15);
    }
  }
`;

const ZoomOnScroling = ({ children }) => {
  const [active, setActive] = useState(false);

  return (
    <Main>
      <InView
        onChange={inView => {
          setActive(inView);
        }}
      >
        <div className={cl('zoomOnScrollingBody', { active })}>{children}</div>
      </InView>
    </Main>
  );
};

ZoomOnScroling.propTypes = {
  children: PropTypes.node
};

export default ZoomOnScroling;
