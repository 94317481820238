export const ourStoryDefault = {
  status: 'PUBLISHED',
  updatedAt: '2020-02-27T08:18:39.400Z',
  createdAt: '2020-02-27T08:07:53.265Z',
  id: 'ck74gti2arcbh0869en3k0cbq',
  title: 'Lịch sử ra đời & phát triển',
  historyTitle1: 'Đầu tiên là C505 chung cư Đào Duy Từ xập xệ',
  historyTitle2: 'F502 + D506 khang trang',
  historyTitle3: 'Team GV + Team Tech hội tụ',
  historyTitle4: '458/14 3/2 focus vào chương trình IELTS',
  historyTitle5: ' Sau đó chưa biết nhưng sẽ nhiều chương trình hơn',
  historyTitle6: null,
  historyContent1: {
    html: '<p>C505 nhận được rất nhiều reviews tốt về phương pháp dạy, anh Đình Lực quyết định qua dạy học full-time vì tình cảm học viên quá lớn.</p>'
  },
  historyContent2: {
    html: '<p>Vì muốn cho học viên chỗ học đẹp đẽ và dễ tiếp thu bài hơn xây F502 D506.</p>'
  },
  historyContent3: {
    html: '<p>Reviews học viên quá tốt hút dc giáo viên khác chung vision + sợ scale lớn thì mất chất khúc chăm học viên nên muốn code nền tảng công nghệ để công nghệ để cá nhân hoá việc học, và giúp học viên luyện tập thêm ở nhà + tiêu rất nhiều tiền ngu cỡ 500k USD may mắn thì dần có DOL TECH cho ra đời 1 số sản phẩm practice ”tạm được”.</p>'
  },
  historyContent4: {
    html: '<p>Học viên đông quá nên xây 458/14 hết 18 tháng, + lúc xây cũng muốn những gì tốt nhất cho học viên nên xây lâu cộng đầu tư CSVC khác biệt + vẫn tập trung cải thiện IELTS.</p>'
  },
  historyContent5: {
    html: '<p>Nhân tài ngày vào càng nhiều vì chung vision + cứ đâm đầu đi với sứ mệnh ngày càng tạo ra các sản phẩm đột phá hơn.</p>'
  },
  historyContent6: {
    html: null
  },
  misstionTitle: 'Sứ mệnh',
  missionContent: {
    html: '<p>DOL English là học viện Anh ngữ ra đời với sứ mệnh tạo ra những sản phẩm dạy tiếng Anh mang tính đột phá để giúp mọi thế hệ học tiếng Anh ở VN dễ dàng nâng cao năng lực Tiếng Anh + Tư Duy, để giúp họ vươn tầm cao mới và làm những điều chưa từng.</p>'
  },
  missionImage: {
    status: 'PUBLISHED',
    updatedAt: '2020-02-17T04:18:34.025Z',
    createdAt: '2020-02-17T04:18:34.025Z',
    id: 'ck6py82p5jjpo0869ydahxvj1',
    handle: 'iBroaN99RkWUXRLXFc6H',
    fileName: 'dol-mission.png',
    height: 2296,
    width: 4300,
    size: 11379966,
    mimeType: 'image/png',
    altText: null,
    title: null
  },
  historyImage: {
    status: 'PUBLISHED',
    updatedAt: '2020-02-18T03:40:50.667Z',
    createdAt: '2020-02-18T03:40:50.667Z',
    id: 'ck6rcbey3neev0869l9jwi817',
    handle: 'vkDe4R53RtW4MNtlkslh',
    fileName: 'background story.png',
    height: 1090,
    width: 3492,
    size: 5046466,
    mimeType: 'image/png',
    altText: null,
    title: null
  },
  visionTitle: 'Tầm nhìn',
  visionImage: {
    status: 'PUBLISHED',
    updatedAt: '2020-02-17T04:18:33.980Z',
    createdAt: '2020-02-17T04:18:33.980Z',
    id: 'ck6py82nwjjpi0869jc2gcclb',
    handle: 'BtPdjnIQUSYmiJFeYy2g',
    fileName: 'dol-vision.png',
    height: 2296,
    width: 4301,
    size: 11548743,
    mimeType: 'image/png',
    altText: null,
    title: null
  },
  visionContent: {
    html: '<p>Trong 10 năm, set standards (là đơn vị tiên phong và dẫn đầu cho việc tạo ra sản phẩm đột phá) cho thị trường dạy Anh ngữ ở mọi chương trình cho mọi lứa tuổi.</p>'
  },
  coreValueTitle: 'Giá trị cốt lõi',
  coreValueImage: {
    status: 'PUBLISHED',
    updatedAt: '2020-02-17T04:18:33.973Z',
    createdAt: '2020-02-17T04:18:33.973Z',
    id: 'ck6py82npjjpe0869flbysf7l',
    handle: 'hR4OHFWbRVCCmXxDeR3q',
    fileName: 'dol-core-value.png',
    height: 2296,
    width: 4301,
    size: 15402150,
    mimeType: 'image/png',
    altText: null,
    title: null
  },
  coreValueContent: {
    html: '<h4>D+ blood, 1.01 ^ 365.</h4><p>D+ blood là máu dolphin, máu cá heo, hay mấy đứa hay chọc là máu điên. Cá heo = helpful + smart. Helpful ở đây là tất cả những thứ muốn làm đều muốn tốt hơn cho học viên, xuất phát từ giúp học viên có được điểm số và tư duy mong muốn. Smart ở đây là thông minh, mà ko phải thông minh kiểu có high IQ là kiểu có khả năg nhìn mọi thứ từ 1 angle khác, có tư duy sáng tạo, đổi mới.</p><p>1.01^365 = 37, nghĩa là mỗi ngày cố 1 chút 1 chút 1 năm ra dc 1 cục bự, học theo cấp số nhân, mỗi ngày đều phải kiểu sáng tạo lên 1 chút biến nó thành 1.01.</p>'
  },
  teamTitle: 'Meet the team',
  teamImage: {
    status: 'PUBLISHED',
    updatedAt: '2020-02-18T04:57:50.841Z',
    createdAt: '2020-02-18T04:57:50.841Z',
    id: 'ck6rf2fw9nnoy0869t6p5yq5a',
    handle: 'tjz9Y9owRWG6uIJm0ViK',
    fileName: 'meet the team.png',
    height: 657,
    width: 2638,
    size: 2558703,
    mimeType: 'image/png',
    altText: null,
    title: null
  },
  team1Title: 'Academic',
  team1Content: {
    html: '<p>Mục tiêu tạo ra những sản phẩm tốt nhất về mặt học thuật giúp học viên học TA hiệu quả hơn, còn học được tư duy.</p>'
  },
  team2Title: 'Center',
  team2Content: {
    html: '<p>Mục tiêu đảm bảo operation ổn nhất, tài liệu, phòng ốc, trà sữa, điểm danh, gọi nhắc đi học, cơ sở vật chất luôn sạch sẽ thoáng mát tạo điều kiện tốt nhất cho việc học.</p>'
  },
  team3Title: 'Consultant',
  team3Content: {
    html: '<p>Mục tiêu định hướng đường đi cho học viên chuẩn nhất, nhanh nhất, tiết kiệm chi phí nhất, theo sát học viên để giải quyết tâm tư nguyện vọng khó nói trong quá trình học.</p>'
  },
  team4Title: 'Marketing',
  team4Content: {
    html: '<p>Mục tiêu truyền tải đúng, đủ và có duyên nhất những giá trị sản phẩm của DOL tới càng nhiều bạn học viên càng tốt.</p><p></p><p></p>'
  },
  team5Title: 'Teach',
  team5Content: {
    html: '<p>Mục tiêu đưa những sản phẩm offline lên online để học viên học tiện nhất, trải nghiệm sướng nhất.</p><p></p><p></p>'
  },
  cultureTitle: 'Culture + working environment',
  cultureImage: {
    status: 'PUBLISHED',
    updatedAt: '2020-02-18T04:06:26.659Z',
    createdAt: '2020-02-18T04:06:26.659Z',
    id: 'ck6rd8c4jnhhy08690zw042cg',
    handle: 'tsvsykpSrOjhSLSQtCG6',
    fileName: 'human centric bg.png',
    height: 3609,
    width: 2688,
    size: 9196622,
    mimeType: 'image/png',
    altText: null,
    title: null
  },
  cultureContent: [
    {
      html: '<p>Nhân viên grow professionally >>> kỷ luật + kpi – oriented, về attitude dc rèn luyện. Văn hoá self-leadership.</p>'
    },
    {
      html: '<p>Thế hệ trẻ dc đào tạo, có trainer, manager đào tạo skill, knowledge. Văn hoá coaching.</p>'
    },
    {
      html: '<p>Nhân viên balance between life and work >>> kỷ luật, hiệu quả, hết việc rồi về.</p>'
    },
    {
      html: '<p>Flat, cởi mở, lắng nghe, mỗi cá nhân điều có original thinking, đều có quyền góp ý lên line manager, CEO. Nếu tốt cty sẵn sàng đập đi xây lại từ đầu, bạn dc thưởng.</p>'
    },
    {
      html: '<p>We value collaboration. Data system.</p>'
    },
    {
      html: '<p>Môi trường tốt nhất cho hoàn thành cv tốt. Foods and drinks are provided.</p>'
    }
  ]
};
