import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typo from '@dolstaff/shared/es/Typo';

import { Section, Container } from '../../Blocks';
import Image from '../../Image';
import { Main, Heading } from '../Common';
import ZoomOnScrolling from '../../ZoomOnScrolling';

import { ourStoryDefault } from '../consts';

const Banner = styled.div`
  position: relative;
  margin-bottom: 5em;

  .background {
    max-width: 880px;
  }

  .content {
    position: absolute;
    top: 10em;
    left: 2em;
    z-index: 1;
    width: 250px;
    height: 60px;

    .emHeading {
      width: 140px;
      position: relative;

      &:after {
        content: '';
        width: 80px;
        height: 2px;
        left: 6em;
        background-color: black;
        transform: rotate(-240deg);
        display: block;
        position: absolute;
      }
    }

    .subtitle {
      font-size: 12px;
      max-width: 60px;
      position: absolute;
      bottom: 0;
      right: 0;
      text-transform: uppercase;
    }
  }

  @media screen and (min-width: 768px) {
    .content {
      top: 23em;
      left: 20em;
      width: 250px;
      height: 60px;

      .emHeading {
        width: 140px;

        &:after {
          width: 80px;
          height: 2px;
          left: 6em;
        }
      }

      .subtitle {
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .content {
      position: absolute;
      top: 2em;
      left: 730px;
      z-index: 1;
      width: 450px;
      height: 160px;

      .emHeading {
        width: 350px;

        &:after {
          width: 200px;
          height: 2px;
          left: 15em;
        }
      }

      .subtitle {
        font-size: 16px;
        max-width: 80px;
      }
    }
  }
`;

const emHeading = {
  handle: 'Vo9EyZEfRDq1A6IlEr4D',
  width: 1444,
  height: 505
};

class CoreValues extends React.PureComponent {
  render() {
    const ourStory = {
      ...ourStoryDefault,
      ...this.props.ourStory
    };

    return (
      <Section>
        <Container>
          <Main>
            <Heading>{ourStory.coreValueTitle}</Heading>
            <Banner>
              <div className="background">
                <ZoomOnScrolling>
                  <Image image={ourStory.coreValueImage} maxWidth={880} />
                </ZoomOnScrolling>
                <div className="content">
                  <div className="emHeading">
                    <Image image={emHeading} maxWidth={350} />
                  </div>
                  <div className="subtitle">giá trị cốt lõi</div>
                </div>
              </div>
            </Banner>
            <Typo
              className="body"
              htmlContent={ourStory.coreValueContent.html}
            />
          </Main>
        </Container>
      </Section>
    );
  }
}

CoreValues.propTypes = {
  ourStory: PropTypes.shape({})
};

export default CoreValues;
