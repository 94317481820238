import { Container, Section } from '../../Blocks';

import Image from '../../Image';
import PropTypes from 'prop-types';
import React from 'react';
import WhiteButton from '../../MyButton/WhiteButton';
import styled from 'styled-components';

const Main = styled.div``;

const HeadingWrp = styled.div`
  position: relative;
  margin-bottom: 8em;

  @media screen and (min-width: 768px) {
    margin-bottom: 2em;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 8em;
  }
`;
const Heading2 = styled.h2`
  font-size: 24px;
  color: #242c34;
  line-height: 1.2;
  margin: 0.5em 0;

  @media screen and (min-width: 768px) {
    max-width: 270px;
    font-size: 40px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 48px;
    max-width: 370px;
  }
`;

const WhiteBox = styled.div`
  border: 1px solid white;
  overflow: hidden;
`;

const StoryList = styled.div`
  margin: -1px;

  .itemWrp {
    border: 1px solid #dfe1e6;
    padding: 24px;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;

    .itemWrp {
      width: calc(100% / 2);
    }
  }

  @media screen and (min-width: 1024px) {
    .itemWrp {
      width: calc(100% / 3);
    }
  }
`;
const StoryItem = styled.div`
  .title {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    line-height: 1.4;
    margin: 0;
  }
  .subtitle {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
  }
  .content {
    font-size: 16px;
    text-align: center;
  }
  .buttonWrp {
    text-align: center;
    margin-top: 1em;
  }

  @media screen and (min-width: 768px) {
    .title {
      font-size: 24px;
    }
    .subtitle {
      font-size: 16px;
    }
    .content {
      font-size: 16px;
    }
    .buttonWrp {
      margin-top: 1em;
    }
  }
`;

const FancyBox = styled.div`
  position: absolute;
  z-index: 1;
  top: 2em;
  left: 0;
  width: 280px;
  height: 90px;

  .emHeading {
    width: 120px;
    position: relative;

    &:after {
      content: '';
      width: 80px;
      height: 2px;
      left: 6em;
      background-color: black;
      transform: rotate(-240deg);
      display: block;
      position: absolute;
    }
  }

  .subtitle {
    max-width: 120px;
    position: absolute;
    bottom: 0;
    right: 0;
    text-transform: uppercase;
  }

  @media screen and (min-width: 768px) {
    top: 0;
    left: 16em;
    width: 280px;
    height: 90px;

    .emHeading {
      width: 120px;

      &:after {
        width: 80px;
        height: 2px;
        left: 6em;
      }
    }

    .subtitle {
      max-width: 120px;
      bottom: 0;
      right: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    top: -2em;
    left: 350px;
    width: 410px;
    height: 180px;

    .emHeading {
      width: 280px;

      &:after {
        width: 200px;
        height: 2px;
        left: 10em;
      }
    }

    .subtitle {
      max-width: 120px;
      position: absolute;
      bottom: 0;
      right: 0;
      text-transform: uppercase;
    }
  }
`;

const ButtonWrp = styled.div`
  text-align: center;
  margin-top: 2em;

  @media screen and (min-width: 1024px) {
    margin-top: 4em;
  }
`;

const emHeading = {
  handle: '0n8IAFPdS6RigwM68GGt',
  width: 1248,
  height: 488
};

class JobList extends React.PureComponent {
  render() {
    const { jobList } = this.props;

    return (
      <Section>
        <Container>
          <Main>
            <HeadingWrp>
              <Heading2>Work and be yourself!</Heading2>
              <FancyBox>
                <div className="emHeading">
                  <Image image={emHeading} maxWidth={350} />
                </div>
                <div className="subtitle">vị trí đang tuyển</div>
              </FancyBox>
            </HeadingWrp>

            <WhiteBox>
              <StoryList>
                {jobList.map(i => (
                  <div className="itemWrp" key={i.slug}>
                    <StoryItem>
                      <h4 className="title">{i.title}</h4>
                      <p className="subtitle">{i.address}</p>
                      <p className="subtitle">{i.salaryDisplay}</p>
                      <p className="content">{i.summary}</p>
                      <div className="buttonWrp">
                        <WhiteButton to={`/job/${i.slug}`}>
                          Ứng tuyển ngay
                        </WhiteButton>
                      </div>
                    </StoryItem>
                  </div>
                ))}
              </StoryList>
            </WhiteBox>

            <ButtonWrp style={{ display: 'none' }}>
              <WhiteButton>See more jobs</WhiteButton>
            </ButtonWrp>
          </Main>
        </Container>
      </Section>
    );
  }
}

JobList.propTypes = {
  jobList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default JobList;
