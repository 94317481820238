import React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import PropTypes from 'prop-types';
import Typo from '@dolstaff/shared/es/Typo';

import { Section, Container } from '../../Blocks';
import Image from '../../Image';
import ZoomOnScrolling from '../../ZoomOnScrolling';

import { ourStoryDefault } from '../consts';

const Main = styled.div``;

const Heading = styled.h2`
  font-size: 24px;
  max-width: 370px;
  line-height: 1.2;
  color: #242c34;
  margin: 0;
  margin-bottom: 1.2em;

  @media screen and (min-width: 768px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 48px;
  }
`;

const StoryList = styled.div``;
const StoryItem = styled.div`
  position: relative;
  margin-bottom: 2em;

  .bullet {
    font-size: 16px;
  }
  .title {
    font-size: 28px;
    line-height: 1.4;
    max-width: 540px;
    margin: 0;
    cursor: pointer;
  }
  .content {
    font-size: 14px;
    line-height: 1.6;
    max-width: 680px;
    display: none;
  }
  &.active {
    .content {
      display: block;
    }

    .title {
      -webkit-text-stroke: 2px black;
      -webkit-text-fill-color: white;
    }
  }

  @media screen and (min-width: 768px) {
    .bullet {
      font-size: 20px;
    }
    .title {
      font-size: 40px;
    }
    .content {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 1024px) {
    .bullet {
      font-size: 20px;
    }
    .title {
      font-size: 40px;
    }
    .content {
      font-size: 20px;
    }
  }
`;

const FancyBox = styled.div`
  position: absolute;
  bottom: -8em;
  right: -1em;
  z-index: 1;
  width: 350px;
  height: 140px;

  .emHeading {
    width: 190px;
    position: relative;

    &:after {
      content: '';
      width: 60px;
      height: 2px;
      right: -3em;
      background-color: black;
      transform: rotate(-240deg);
      display: block;
      position: absolute;
    }
  }

  .subtitle {
    font-size: 12px;
    max-width: 200px;
    position: absolute;
    bottom: 0;
    right: 0;
    text-transform: uppercase;
  }

  @media screen and (min-width: 768px) {
    bottom: -5.5em;
    right: 0em;
    width: 450px;
    height: 120px;

    .emHeading {
      width: 190px;

      &:after {
        width: 60px;
        height: 2px;
        right: -3em;
      }
    }

    .subtitle {
      font-size: 12px;
      max-width: 200px;
      bottom: 0;
      right: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    bottom: -4em;
    right: 0;
    width: 820px;
    height: 120px;

    .emHeading {
      width: 320px;
      position: relative;

      &:after {
        width: 120px;
        height: 2px;
        right: -4em;
      }
    }

    .subtitle {
      font-size: 16px;
      max-width: 460px;
      bottom: 0;
      right: 0;
    }
  }
`;

const Banner = styled.div`
  margin-right: 24px;
  margin-bottom: 9em;

  .bannerWrp {
    position: relative;
  }
  .backgroundImg {
    max-height: 450px;
    overflow: hidden;
  }

  @media screen and (min-width: 768px) {
    margin-right: 24px;
    margin-bottom: 5em;
  }

  @media screen and (min-width: 1024px) {
    margin-right: 8em;
    margin-bottom: 6em;
  }
`;

const emHeading = {
  handle: 'YwGymEkSURoKY13khCgc',
  width: 1941,
  height: 524
};

class DolTeam extends React.PureComponent {
  state = {
    activeIndex: 0
  };

  setActiveIndex = activeIndex => this.setState({ activeIndex });

  render() {
    const { activeIndex } = this.state;
    const ourStory = {
      ...ourStoryDefault,
      ...this.props.ourStory
    };

    return (
      <Section>
        <Container>
          <Heading>{ourStory.teamTitle}</Heading>
        </Container>
        <Banner>
          <div className="bannerWrp">
            <FancyBox>
              <div className="emHeading">
                <Image image={emHeading} maxWidth={460} />
              </div>
              <div className="subtitle">
                giúp học viên nâng cao năng lực tiếng anh, tư duy để vươn tầm
                cao mới, làm điều chưa từng
              </div>
            </FancyBox>
            <div className="backgroundImg">
              <ZoomOnScrolling>
                <Image image={ourStory.teamImage} maxWidth={1440} />
              </ZoomOnScrolling>
            </div>
          </div>
        </Banner>

        <Container>
          <Main>
            <StoryList>
              <StoryItem
                className={cl({ active: activeIndex === 0 })}
                onClick={() => this.setActiveIndex(0)}
              >
                <div className="bullet">01 / Team</div>
                <h4 className="title">{ourStory.team1Title}</h4>
                <Typo
                  className="content"
                  htmlContent={ourStory.team1Content.html}
                />
              </StoryItem>
              <StoryItem
                className={cl({ active: activeIndex === 1 })}
                onClick={() => this.setActiveIndex(1)}
              >
                <div className="bullet">02 / Team</div>
                <h4 className="title">{ourStory.team2Title}</h4>
                <Typo
                  className="content"
                  htmlContent={ourStory.team2Content.html}
                />
              </StoryItem>
              <StoryItem
                className={cl({ active: activeIndex === 2 })}
                onClick={() => this.setActiveIndex(2)}
              >
                <div className="bullet">03 / Team</div>
                <h4 className="title">{ourStory.team3Title}</h4>
                <Typo
                  className="content"
                  htmlContent={ourStory.team3Content.html}
                />
              </StoryItem>
              <StoryItem
                className={cl({ active: activeIndex === 3 })}
                onClick={() => this.setActiveIndex(3)}
              >
                <div className="bullet">04 / Team</div>
                <h4 className="title">{ourStory.team4Title}</h4>
                <Typo
                  className="content"
                  htmlContent={ourStory.team4Content.html}
                />
              </StoryItem>
              <StoryItem
                className={cl({ active: activeIndex === 4 })}
                onClick={() => this.setActiveIndex(4)}
              >
                <div className="bullet">05 / Team</div>
                <h4 className="title">{ourStory.team5Title}</h4>
                <Typo
                  className="content"
                  htmlContent={ourStory.team5Content.html}
                />
              </StoryItem>
            </StoryList>
          </Main>
        </Container>
      </Section>
    );
  }
}

DolTeam.propTypes = {
  ourStory: PropTypes.shape({})
};

export default DolTeam;
