import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Typo from '@dolstaff/shared/es/Typo';

import { Section, Container } from '../../Blocks';
import Image from '../../Image';
import ZoomOnScrolling from '../../ZoomOnScrolling';

import { ourStoryDefault } from '../consts';

const Main = styled.div`
  margin-top: 6em;
  margin-bottom: 6em;

  .body {
    font-size: 20px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 12em;
    margin-bottom: 12em;

    .body {
      font-size: 20px;
    }
  }
`;

const Heading = styled.h2`
  font-size: 24px;
  max-width: 600px;
  line-height: 1.2;
  margin: 0.5em 0;
  color: #242c34;

  @media screen and (min-width: 768px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 48px;
  }
`;

const FancyBox = styled.div`
  position: absolute;
  top: 4em;
  left: 6em;
  z-index: 1;
  width: 200px;
  height: 180px;

  .emHeading {
    width: 200px;
    position: relative;

    &:after {
      content: '';
      width: 200px;
      height: 2px;
      background-color: black;
      transform: rotate(-240deg);
      display: block;
      position: absolute;
      right: -6em;
      display: none;
    }
  }

  .subtitle {
    position: absolute;
    text-transform: uppercase;
    max-width: 320px;
    bottom: 0;
    right: 0;
  }

  @media screen and (min-width: 768px) {
    top: 8em;
    left: 16em;
    width: 250px;
    height: 180px;

    .emHeading {
      width: 250px;

      &:after {
        width: 200px;
        height: 2px;
        right: -6em;
      }
    }

    .subtitle {
      max-width: 320px;
      bottom: 0;
      right: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    top: 4em;
    left: 320px;
    z-index: 1;
    width: 320px;
    height: 180px;

    .emHeading {
      width: 320px;

      &:after {
        width: 200px;
        height: 2px;
        right: -6em;
      }
    }

    .subtitle {
      max-width: 320px;
      bottom: 0;
      right: 0;
    }
  }
`;

const List = styled.div``;
const CultureItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  max-width: 600px;

  .bullet {
    font-size: 20px;
    flex: 50px 0 0;
  }
  .content {
    font-size: 16px;
    margin-bottom: 0;

    p {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 768px) {
    .content {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 1024px) {
    .bullet {
    }
    .content {
      font-size: 20px;
    }
  }
`;

const Panel = styled.div`
  .panelLeft {
    position: relative;
    .imgWrp {
      width: 50%;
    }
  }
  .panelRight {
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: flex-start;

    .panelLeft {
      flex: 40% 0 0;
      .imgWrp {
        width: 100%;
      }
    }
    .panelRight {
      flex: 60%;
      margin-top: 12em;
      margin-left: 4em;
    }
  }
`;

const emHeading = {
  handle: 'pWuBVCwkRoeLeCD4Qkgq',
  width: 1883,
  height: 586
};

class DolCulture extends React.PureComponent {
  render() {
    const ourStory = {
      ...ourStoryDefault,
      ...this.props.ourStory
    };

    return (
      <Section>
        <Container>
          <Main>
            <Panel>
              <div className="panelLeft">
                <div className="imgWrp">
                  <ZoomOnScrolling>
                    <Image image={ourStory.cultureImage} maxWidth={500} />
                  </ZoomOnScrolling>
                </div>
                <FancyBox>
                  <div className="emHeading">
                    <Image image={emHeading} maxWidth={460} />
                  </div>
                </FancyBox>
              </div>
              <div className="panelRight">
                <Heading>
                  Culture + <br />
                  working environment
                </Heading>

                <div className="body">
                  <List>
                    {ourStory.cultureContent.map((i, index) => (
                      <CultureItem key={i.id}>
                        <div className="bullet">0{index + 1}</div>
                        <Typo className="content" htmlContent={i.html} />
                      </CultureItem>
                    ))}
                  </List>
                </div>
              </div>
            </Panel>
          </Main>
        </Container>
      </Section>
    );
  }
}

DolCulture.propTypes = {
  ourStory: PropTypes.shape({})
};

export default DolCulture;
